@import '../../sass/base';

.table-home{
  width: 100%;
  min-width: 1400px;
  color: white;
  border-collapse: separate;
  border-spacing: 0 10px;
  tr{
    padding-top: 1rem;
    padding-bottom:1rem;
    max-height: 40px;
    cursor: pointer;
    &:first-child{
      cursor: default;
    }
    th{
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      background-color: $action-color;
      cursor: pointer;
      font-size: 0.9rem;
      border-top-left-radius: 10px 0 0 10px;
      max-width: 12.5%;
    }
    td{
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      color: rgba(255, 255, 255, 0.753);
      margin-left: 1rem;
      margin-right: 1rem;
      background-color: #212D33;
      max-width: 12.5%;
      .close{
        background-color: $action-color;
        border-radius: 50%;
        height: 0.6rem;
        width: 0.6rem;
      }
    }
    .redirect-modal{
      font-size: 1.2rem;
      font-weight: bolder;
    }
    th:first-child {
      padding-left: 1rem;
      border-radius: 0.5rem 0 0 0;
    }
    th:last-child {
      border-radius: 0 0.5rem 0 0;
    }
    td:first-child{
      padding-left: 0.3rem;
      padding-right: 0.5rem;
    }
    td:last-child{
      padding-left: 0.3rem;
      padding-right: 0.5rem;
    }
  }
}

.mobile-home{
  color: white;

  .mobile-card {
    border-radius: 10px;
    overflow: hidden;

    > div {
      height: 53px;
      border-bottom: solid 1px $muted-text-color;
      background-color: #212D33;

      &.read-status{
        height: 5px;
        background-color: $action-color;

        &.readed{
          background-color: $dark-gray-2-color;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;

  .modal-deal {
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background-color: #263238;
    z-index: 1501;
    padding: 2rem;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar{
      width: 8px;
      height: 0.5rem;
      border-radius: 0.5rem;
    }
    &::-webkit-scrollbar-track{
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
      height: 0.5rem;
      background-color: $muted-text-color;
      border-radius: 0.5rem; 
    }
    @media (min-width: 992px) {
      width: 700px;
      border-radius: 2rem;
      margin-top: 2rem;
    }

    .close{
      color:white;
      font-size: 2rem;
      height: fit-content;
      max-height: 93vh;
    }
  }
}

.dealme-submission-right-side-caption-container {
  padding: 0;
  margin: 3.125rem 0 4.5rem 0;
  color: white;
  text-align: left;
  position: relative;

  @include phone {
    margin: 4.5rem 0 1.875rem 0;
  }

  @include tablet {
    margin: 4.5rem 0 1.875rem 0;
  }

  h2 {
    margin: 0;
    font-weight: Bold;
    font-family: Montserrat;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: left;

    @include phone {
      font-weight: normal;
      line-height: 1.813rem;
    }

    @include tablet {
      font-weight: normal;
      line-height: 1.813rem;
    }
  }

  p {
    margin: 0.563rem 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: left;

    @include phone {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    @include tablet {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
}

.dealme-submission-form-container {
  padding: 0;

  .dealme-submission-form-container-form {
    .dealme-submission-form-container-form-button {
      width: 114px;
      height: 40px;
      font-size: 0.875rem;
      line-height: 1.063rem;
      margin-top: 30px;

      @include primary-button;
    }

    .form-group.col-md-6 {
      @include tablet {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .row {
      margin: 0;
    }

    .form-group {
      padding-left: 0;
      padding-right: 1.875rem;
      margin-bottom: 1.25rem;

      @include phone {
        padding-right: 0;
      }

      @include tablet {
        padding-right: 0;
      }

      input.form-control {
        margin-right: -22px;

        @include dark-gray-bordered-input;
      }

      .dealme-submission-form-container-form-input-error-messageinput-error {
        @include input-with-error;
      }

      .dealme-submission-form-container-form-input-error-message {
        top: 0.625rem;
        line-height: 14px;
        position: relative;

        @include error-feedback-label;
      }
      textarea {
        @include dark-gray-bordered-textarea;
      }
    }

    .form-label {
      font-size: 12px;
      line-height: 14px;

      @include white-primary-label;
    }
  }
  &.isModal {
    .form-group {
      input.form-control , textarea {
        background-color: #263238;
        padding: 0;
        &:focus {
          border: none!important;
        }
      }
    }
  }
  .on-field-icon{
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
  }
  &.isEdit {
    input{
      padding-right: 35px;
    }
    .on-field-icon{
      position: absolute;
      top: 0.75rem;
      right: 2.5rem;
    }
  }
}
.pagination {
  .page-link {
    width: 44px;
    height: 44px;
    background-color: inherit;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-right: 40px;
    border: none;
    padding: 10px 18px;
    border-radius: 5px;
    
    &.active {
      background-color: $primary-color;
    }
  }
}

.isEdit
.dealme-submission-form-container-form-input-error-message, .dealme-submission-form-container-form-input-error-messageinput-error {
  display: none;  
}

.green-button {
  width: 11.063rem;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.063rem;
  margin-bottom: 2.5rem;
  color: white;
  @include primary-button;
}