@import '../../sass/base';

.change-phone-form {
  position: relative;
  background-color: $light-dark-background-color;
  border-radius: 30px;
  padding: 0;

  @include phone {
    background: none;
  }

  .container {
    width: 100%;
    padding: 0;
  }

  .form-label {
    font-size: 0.75rem;
    line-height: 0.875rem;

    @include white-primary-label;
  }

  .change-phone-form-form {
    padding: 1.125rem;

    @include phone {
      padding: 0;
    }

    .form-group {
      display: flex;
          flex-wrap: nowrap;
          margin-bottom: 0;
          justify-content: space-between;

      input.form-control {
        @include dark-gray-bordered-input;
      }
      .dealme-dark-gray-bordered-select__control {
        width: 100px;
      }
      .dealme-dark-gray-bordered-select__menu {
        width: 100px;
        background: $light-dark-background-color;
      }
      .dealme-dark-gray-bordered-select__single-value {
        color: white;
        font-size: 0.875rem;
        line-height: 1rem;
      }
      .dealme-dark-gray-bordered-select__input {
        color: white;
      }
      .dealme-dark-gray-bordered-select__option {
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
      .dealme-dark-gray-bordered-select__option:hover {
        background: $muted-text-color;
      }
      .dealme-dark-gray-bordered-select__indicator {
        color:  $muted-text-color;
      }
      .dealme-dark-gray-bordered-select__indicator:hover {
        color:  $muted-text-color;
      }

      :nth-child(1) {
        padding-right: 0.313rem;
      }

      :nth-child(2) {
        margin-right: 0px;
      }

      .change-phone-form-form-input-error {
        @include input-with-error;
      }

      .change-phone-form-form-input-error-message {
        @include error-feedback-label;
      }
    }
  }

  .change-phone-form-form-submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0 0 0;

    @include phone {
      margin: 1.25rem 0 0 0;
    }

    .container {
      padding: 0;
    }

    .btn-link {
      padding: 0;
      font-size: 0.75rem;
      line-height: 0.938rem;

      @include primary-link-button;
    }

    .change-phone-form-form-submit-container-cta-container {
      width: 100%;
      display: flex;
      text-align: center;
      flex-direction: row;
      justify-content: space-between;
      margin: 2.5rem 0 0 0;

      @include phone {
        margin: 0;
      }
    }

    .change-phone-form-form-submit-container-submit-button {
      width: 11.063rem;
      height: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.063rem;
      margin-bottom: 2.5rem;

      @include primary-button;

      @include phone {
        width: 100%;
        padding: 0;
      }
    }
  }
}
