@import '../../../sass/base';


.dealme-modal-container-hidden{
  display: none;
}
.dealme-modal-container {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  z-index: 1010;
  background-color: $light-dark-background-color;
  border-radius: 30px;
  padding:30px;
  width: fit-content;

  h2{
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.3px;
    color: $muted-text-color;
  }
      
  @include white-primary-label;
  
  .dealme-modal-container-body{
    display: flex;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    // padding: 0 30px 30px 30px;
  }

  .dealme-modal-footer{
    align-items: center;    
    padding-top: 30px
  }
  .dealme-modal-cancel-button{
    border: 2px solid $primary-color;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 30px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    background-color: transparent;
    color: $white;
    margin: 10px;
  }
  .dealme-modal-confirm-button{
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    background: $primary-color;
    padding: 10px 30px;
    border-radius: 10px;
    border: none!important;
    margin: 10px;
  }

}

.dealme-modal-container-title-wrapper{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  
  Button{
    border: none;
    background-color: transparent;
  }
}

.dealme-modal-close-button{
  img{
    width: 15px;
    margin-bottom: 0.5rem;
  }
  padding-left: 30px;
}

.dealme-modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1009;
  background-color: rgba(18, 30, 36, 0.8);
  top:0;
  left: 0;
}
.dealme-modal-overlay-hidden{
  display: none;
}
// @media screen and (min-width: 1025px) {
//   .dealme-modal-overlay {
//     top: -92px;
//     right: -30px;
//   }
//   .dealme-modal-container{
//     // width: 50%;
//     width: min-content;
//     // margin-left: 25%;
//     margin:auto;
//   }
// }
// @media screen and (max-width: 768px) {
//   .dealme-modal-overlay {
//     top: 0px;
//   }
// }
@media screen and (max-width: 425px) {
  .dealme-modal-manager-button-options-container{
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .dealme-modal-container{
    // margin-left: 12vw;
    margin:auto;
  }
}

// @media screen and (min-width: 769px) and (max-width: 1024px) {
//   .dealme-modal-overlay {
//     top: -92px;
//     left: -290px;
//   }
// }



