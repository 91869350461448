@import '../../sass/base';

.dealme-signup {
  .dealme-signup-logo:hover {
    cursor: pointer;
  }

  .dealme-signup-left-side-container {
    width: 50%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    position: relative;
    align-items: flex-end;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-image: url('../../assets/Signup/cover.jpg');
    padding-left: 0;

    .dealme-signup-left-side-image {
      width: 50vw;
      min-height: 100vh;
      max-height: 100vh;
      display: flex;
      position: fixed;
      top:0;
      align-items: flex-end;
    }

    @include phone {
      display: none;
    }

    @include tablet {
      display: none;
    }

    .dealme-signup-logo-transparent {
      // width: 36.31rem;
      // height: 17.75rem;
      display: block;
      // margin-left: auto;
      // margin-right: auto;
      // margin-bottom: 70px;
      margin-top: -4vh;
      width: 30vw;
      height: auto;
      position: fixed;
      margin-left: 10vw;
    }
  }

  .dealme-signup-right-side-container {
    width: 50%;

    @include phone {
      width: 100%;
    }

    @include tablet {
      width: 100%;
    }

    svg {
      @include phone {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      @include tablet {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }

  .dealme-signup-right-side-inner-container {
    padding: 8.43rem 4.188rem 8.43rem 4.188rem;

    @include phone {
      padding: 51px 0px 51px 0px;
    }

    @include tablet {
      padding: 51px 0px 51px 0px;
    }
  }

  .dealme-signup-right-side-caption-container {
    top: 5.66rem;
    height: 6rem;
    color: white;
    text-align: left;
    position: relative;

    @include phone {
      top: 3rem;
      margin-bottom: 1.875rem;
    }

    @include tablet {
      top: 3rem;
      margin-bottom: 1.875rem;
    }

    p {
      margin: 0.625rem 0 0 0;
      font-style: normal;
      font-weight: normal;
      font-family: Montserrat, sans-serif;
    }

    p:nth-child(1) {
      margin: 0;
      font-size: 30px;
      line-height: 37px;
    }

    p:nth-child(2) {
      font-size: 20px;
      line-height: 24px;
    }

    p:nth-child(3) {
      font-size: 14px;
      line-height: 17px;
      color: $muted-text-color;
    }
  }

  .dealme-signup-form-container {
    top: 8.75rem;
    padding: 2.125rem 3.125rem 2.125rem 3.125rem;
    position: relative;
    background: $light-dark-background-color;
    border-radius: 30px;

    @include phone {
      top: 1.875rem;
      background: none;
      border-radius: none;
      padding: 1.25rem
    }

    @include tablet {
      top: 3rem;
    }

    .dealme-signup-form {
      .input-group {
        padding: 0;

        .input-group-append {
          align-items: center;
        }
      }

      .form-group {
        margin-bottom: 1.25rem;

        input.form-control {
          margin-right: -22px;

          @include dark-gray-bordered-input;
        }

        .dealme-signup-form-input-error {
          @include input-with-error;
        }

        svg {
          z-index: 999;
          right: 11px;
          position: relative;
        }

        svg:hover {
          cursor: pointer;
        }

        .dealme-signup-form-input-error-message {
          top: 0.625rem;
          line-height: 14px;
          position: relative;
          @include error-feedback-label;
        }
      }

      .dealme-signup-form-phone-form-group {
        .form-group {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 0;
          justify-content: space-between;

          .dealme-dark-gray-bordered-select__control {
            width: 100px;
          }

          .dealme-dark-gray-bordered-select__menu {
            width: 100px;
          }

          .dealme-dark-gray-bordered-select__single-value {
            color: white;
            font-size: 0.75rem;
            line-height: 0.875rem;
          }

          .dealme-dark-gray-bordered-select__input {
            color: white;
          }

          .dealme-dark-gray-bordered-select__option {
            font-size: 0.75rem;
            line-height: 0.875rem;
          }

          :nth-child(1) {
            padding-right: 0.313rem;
          }

          :nth-child(2) {
            margin-right: 0px;
          }
        }
      }

      .form-label {
        font-size: 12px;
        line-height: 14px;

        @include white-primary-label;
      }
    }
  }

  .dealme-signup-form-submit-container {
    top: 2.5rem;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include phone {
      margin: 0;
    }

    button {
      width: 7.375rem;
      height: 2.5rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem;

      @include phone {
        width: 100%;
        height: 3.75rem;
      }
    }

    button:nth-child(1) {
      font-size: 14px;
      line-height: 17px;
      @include primary-button;
    }

    button:nth-child(2) {
      width: 100%;
      font-size: 12px;
      line-height: 15px;

      @include primary-link-button;
    }
  }
}
